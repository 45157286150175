<template>
  <v-app>
    <v-parallax
        :src="backgroundImage.imageUrl"
        class="mt-n16"
        height="600"
    >

      <div class="d-flex flex-column fill-height justify-center align-center text-white">
        <h1 class="text-h3 font-weight-thin px-16 mx-16 mb-8">
          L'investissement le plus important que vous puissiez faire est en vous même
        </h1>
        <h4 class="subheading">
          Warren Buffet
        </h4>

          <v-container class="w-25 text-center mt-10">
            <v-row justify="center">
              <v-col>
                <v-btn rounded="lg" color="#D7BB4C" size="x-large" @click="$router.push('/articles')">Articles</v-btn>
              </v-col>

              <v-col>
                <v-btn rounded="lg" size="x-large"  @click="$router.push('/boutique')">Boutique</v-btn>
              </v-col>
            </v-row>
          </v-container>

      </div>

    </v-parallax>
    <h1 class="mt-16">Bienvenue sur R.I.C.H
    </h1>
    <v-row class="mt-10 mx-16">
        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ut libero nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur ac pharetra enim. Donec vitae odio dapibus neque placerat tincidunt. Pellentesque sodales at elit id fringilla. Ut accumsan sed elit sed gravida. Sed lacinia placerat est eu facilisis. Phasellus ullamcorper semper tortor, quis scelerisque tortor. Curabitur sapien magna, interdum sit amet rhoncus ut, lobortis eget dui. Mauris a metus at libero tristique dictum. Suspendisse a metus in velit feugiat dictum ac in dui. Sed interdum, felis nec malesuada ultrices, est nibh tincidunt dui, id efficitur odio leo eget justo. Proin rhoncus non justo vel efficitur. Mauris sed nisi ut ante consequat aliquet vel nec sem. Mauris eget lacinia lectus, vel congue odio.</h3>

    </v-row>
    <v-row class="d-flex justify-center mt-16">
      <v-img
          max-height="600"
          min-height="600"
          src="https://picsum.photos/1920/1080?random"
      ></v-img>
    </v-row>
    <Carousel class="mt-10 mx-8">
      <Slide v-for="(product, index) in products" :key="index">
        <div class="carousel__item" :style="{ backgroundImage: `url(${product.selectedImage})` }">
          <div class="flex-container">
            <v-chip color="#FFFFF" style="background-color: #D9B436">
              {{ product.category }}
            </v-chip>
            <p class="text-overline mt-3 ml-4">{{ product.nameArticle }}</p>
          </div>
          <v-card-subtitle>{{ product.catchPhrase }}</v-card-subtitle>
        </div>
      </Slide>
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </v-app>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import articlesApi from "@/services/articles"
import backgroundImageApi from "@/services/backgroundImages"

export default {
  name: 'HomeView',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      products: [],
      backgroundImage: ""
    };
  },
  async mounted() {
    try {
      const resArticles = await articlesApi.getArticlesAndElements();
      const resBackgroundImage = await backgroundImageApi.getSpecificBackgroundImage(this.$route.name);

      this.products = resArticles.data.length > 0 ? resArticles.data : [];
      this.backgroundImage = resBackgroundImage.data.length > 0 ? resBackgroundImage.data[0] : "";
    } catch (error) {
      console.error("Error:", error);
    }
  },
}
</script>

<style scoped>
@import 'vue3-carousel/dist/carousel.css';
.carousel__item {
  height: 300px;
  width: 100%;
  background-color: #f0f0f0;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 3rem;
}
.flex-container {
  display: flex;
  align-items: center; /* Aligne les éléments verticalement au centre */
}
</style>